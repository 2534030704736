<template>
	<S2SForm title="Cash Centres">
		<v-flex xs12 class="text-xs-left text-right pb-2">
			<v-btn class="ml-0" :block="$vuetify.breakpoint.xsOnly" color="accent" :to="{ name: 'create-cash-centre' }" cy-data="add-cash-centre"
				>Add Cash Centre</v-btn
			>
		</v-flex>

		<v-layout justify-end>
			<v-flex xs12 sm6 class="mb-2">
				<v-text-field label="Filter Cash Centre" v-model="search" clearable hide-details></v-text-field>
			</v-flex>
		</v-layout>
		<v-data-table :search="search" :headers="headers" :items="cashCentres" class="elevation-1" :loading="status.loading" :options.sync="pagination">
			<template v-slot:item.action="{ item }">
				<v-btn text medium @click="editCentre(item.id)">
					<v-icon>edit</v-icon>
				</v-btn>
			</template>
		</v-data-table>
	</S2SForm>
</template>

<script>
import Vue from "vue";
import { Status } from "@/store/utils";
import cashcentreSchema from "@/static/config/forms/cashcentre-schema.json";
import { CashCentreBody } from "../../api/safes-api";

export default Vue.extend({
	name: "CashCentres",

	data: function() {
		return {
			cashcentreSchema: cashcentreSchema,
			search: "",
			headers: [
				{
					text: "Id",
					value: "id",
					align: "left"
				},
				{
					text: "Description",
					value: "description",
					align: "left"
				},
				{
					text: "Contact Name",
					value: "contactName",
					align: "left"
				},
				{
					text: "Contact Number",
					value: "contactNumber",
					align: "left"
				},
				{
					text: "Email",
					value: "email",
					align: "left"
				},
				{
					text: "",
					value: "action",
					align: "right"
				}
			],
			pagination: {
				descending: false,
				itemsPerPage: 15
			}
		};
	},

	computed: {
		cashCentres: function() {
			return this.$store.state.safes.cashCentres;
		},
		status: function() {
			return this.$store.state.safes.status;
		}
	},

	created: function() {
		this.$store.dispatch("safes/fetchCashCentres");
	},

	methods: {
		editCentre(id) {
			this.$router.push({ name: "edit-cash-centre", params: { centreId: id.toString() } });
		}
	}
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
